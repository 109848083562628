import * as React from 'react'
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import Modal from '../components/modal'
import SEO from '../components/seo'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {trackCustomEvent} from 'gatsby-plugin-google-analytics'

const BlogPostTemplate = ({data}) => {
  const allArticles = data.allMarkdownRemark.edges
  const [searchTerm, setSearchTerm] = React.useState('')

  const normalizeString = str =>
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()

  // Separate featured and regular articles
  const featuredArticles = allArticles.filter(
    article => article.node.frontmatter?.featured,
  )
  const regularArticles = allArticles.filter(
    article => !article.node.frontmatter?.featured,
  )

  // Filter both featured and regular articles based on the search term
  const filteredFeaturedArticles = featuredArticles.filter(({node}) => {
    const title = normalizeString(node.frontmatter.title)
    const excerpt = normalizeString(node.excerpt)
    const search = normalizeString(searchTerm)
    return title.includes(search) || excerpt.includes(search)
  })

  const filteredRegularArticles = regularArticles.filter(({node}) => {
    const title = normalizeString(node.frontmatter.title)
    const excerpt = normalizeString(node.excerpt)
    const search = normalizeString(searchTerm)
    return title.includes(search) || excerpt.includes(search)
  })

  const handleSearchChange = event => {
    const query = event.target.value
    setSearchTerm(query)

    // Track search query event with Google Analytics
    if (query) {
      trackCustomEvent({
        category: 'Search',
        action: 'Search Query',
        label: query, // Send the search term
      })
    }
  }

  return (
    <Layout>
      <SEO
        title="Aprende Francés: Artículos de Blog - Lemot"
        description="Descubre artículos informativos sobre la lengua y cultura francesa. Aprende francés con consejos y recursos útiles en el blog de Lemot."
        hasJsonLd
      />
      <div className="wrapper">
        <Modal />
        <section className="section is-medium">
          <h1 className="title has-text-centered">
            Artículos y canciones sobre la cultura francesa.
          </h1>
          <div className="field">
            <div className="control">
              <input
                className="input is-large is-rounded"
                type="text"
                placeholder="Buscar artículos..."
                value={searchTerm}
                onChange={handleSearchChange} // Track search query
              />
            </div>
          </div>
        </section>

        {/* Section for Featured Articles */}
        {filteredFeaturedArticles.length > 0 && (
          <section className="section is-small">
            <h2 className="title is-4 has-text-centered">
              Artículos Destacados
            </h2>
            <div className="columns is-multiline">
              {filteredFeaturedArticles.map(({node}) => {
                const {frontmatter, excerpt} = node
                const {date, title, slug, type, image, featured} = frontmatter
                const formattedDate = format(new Date(date), 'dd MMMM, yyyy', {
                  locale: es,
                })

                return (
                  <div key={slug} className="column is-half">
                    <div
                      style={{
                        borderRadius: 8,
                        boxShadow: '3px 4px 0px black',
                        border: '2px solid black',
                        height: '100%',
                      }}
                      className="card"
                    >
                      <header
                        style={{
                          padding: '1rem 1.5rem',
                          display: 'flex',
                          justifyContent: 'right',
                          position: 'absolute',
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        {featured && (
                          <span style={{marginRight: '0.5rem'}}>⭐</span>
                        )}
                        {type === 'song' ? <span>🎵</span> : <span>📓</span>}
                      </header>
                      <Link to={`/blog/${slug}`}>
                        {image && (
                          <div
                            className="card-image"
                            style={{
                              height: '200px',
                              overflow: 'hidden',
                              borderRadius: '6px 6px 0 0',
                              borderBottom: '2px solid black',
                            }}
                          >
                            <figure
                              className="image"
                              style={{height: 'auto', width: '100%'}}
                            >
                              <img
                                src={image}
                                alt={title}
                                style={{
                                  objectFit: 'cover',
                                  width: '100%',
                                  height: '100%',
                                }}
                              />
                            </figure>
                          </div>
                        )}
                        <div
                          style={{
                            minHeight: 200,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                          className="card-content"
                        >
                          <h2 className="title is-5">{title}</h2>
                          <div
                            style={{flexDirection: 'column', color: 'black'}}
                            className="transparent_gradient"
                          >
                            <div className="is-size-6">{excerpt}</div>
                          </div>
                          <br />
                          <p className="subtitle is-7 has-text-grey">
                            {formattedDate}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>
        )}

        {/* Section for Regular Articles */}
        <section className="section is-small">
          <h2 className="title is-4 has-text-centered">Todos los Artículos</h2>
          <div className="columns is-multiline">
            {filteredRegularArticles.length > 0 ? (
              filteredRegularArticles.map(({node}) => {
                const {frontmatter, excerpt} = node
                const {date, title, slug, type, image} = frontmatter
                const formattedDate = format(new Date(date), 'dd MMMM, yyyy', {
                  locale: es,
                })

                return (
                  <div key={slug} className="column is-full">
                    <div className="card">
                      <header
                        style={{
                          padding: '1rem 1.5rem',
                          display: 'flex',
                          justifyContent: 'right',
                          position: 'absolute',
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        {type === 'song' ? <span>🎵</span> : <span>📓</span>}
                      </header>
                      <Link to={`/blog/${slug}`}>
                        {image && (
                          <figure
                            className="image"
                            style={{
                              height: 'auto', // Allow image height to adjust
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={image}
                              alt={title}
                              style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '200px', // Reduced height for image
                              }}
                            />
                          </figure>
                        )}
                        <div
                          style={{
                            minHeight: 200,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                          className="card-content"
                        >
                          <h2 className="title is-5">{title}</h2>
                          <div
                            style={{flexDirection: 'column', color: 'black'}}
                            className="transparent_gradient"
                          >
                            <div className="is-size-6">{excerpt}</div>
                          </div>
                          <br />
                          <p className="subtitle is-7 has-text-grey">
                            {formattedDate}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="column is-full">
                <p className="has-text-centered">
                  No se encontraron artículos.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            type
            featured
            image
          }
          excerpt(pruneLength: 300)
        }
      }
    }
  }
`

export default BlogPostTemplate
